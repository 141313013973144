<div class="text-center heading w-100" translate>Please upload your ID</div>
<ng-container *ngIf="!isSaved else isSavedTemplate;">
  <div class="text-center title w-100" translate>ID Verification</div>
  <div class="flex-grow-1 text-center description" translate>
    {{ "Please upload your ID first to apply to a job." | translate }}
    <br />
    <br />
    Only your photo, name and date of birth need to be visible. All the other information can be masked. We take as many
    precautions as possible to prevent people pretending to be someone else
  </div>
  <div class="d-flex flex-column align-items-center bottom-container">
    <input
      #picture
      type="file"
      class="d-none"
      (input)="onValueChange($event.target.files)"
      accept="image/png, image/jpg, image/jpeg" />
    <div class="photo-id d-flex flex-column align-items-center justify-content-center" (click)="chooseImage()">
      <div *ngIf="!form.get('idVerificationFile').value && !idImageURL" translate>Upload ID</div>
      <app-image
        *ngIf="form.get('idVerificationFile').value || idImageURL"
        [image]="form.get('idVerificationFile').value || idImageURL"
        class="preview h-100 w-100"></app-image>
    </div>
    <app-solid-button
      (btnClick)="handleSubmit()"
      buttonStyle="purple-blue"
      [isDisabled]="!form.get('idVerificationFile').value || inProgress"
      [isBusy]="inProgress">
      {{ "Submit" | translate }}
    </app-solid-button>
  </div>
</ng-container>

<ng-template #isSavedTemplate>
  <div class="flex-grow-1 d-flex align-items-center flex-column justify-content-center">
    <div class="save-desc text-center" translate>
      Your ID has been uploaded for review-check. If you haven't received an email within 12 hours, please contact via
      <a href="mailto:talents&#64;casterbee.id">talents&#64;casterbee.id</a>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center bottom-container">
    <app-solid-button (btnClick)="handleClose()" buttonStyle="transparent">{{ "Close" | translate }}</app-solid-button>
  </div>
</ng-template>
